@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .columns-2 {
    column-count: 2;
  }
}

@layer utilities {
  /* For Firefox Browser */
  .scrollbar-swiper {
    scrollbar-color: theme(colors.blue.DEFAULT) rgba(0,0,0,0.1);
  }

  /* For Chrome, EDGE, Opera, Others */
  .scrollbar-swiper::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: rgba(0, 0, 0, 0.1);
  }

  .scrollbar-swiper::-webkit-scrollbar-thumb {
    @apply bg-blue;
  }
}

:root {
  --swiper-theme-color: theme('colors.blue.DEFAULT');
  --swiper-navigation-size: 36px;
}

@import 'swiper/swiper-bundle.css';

.swiper-horizontal > .swiper-scrollbar {
  height: 3px;
}

.swiper-scrollbar-drag {
  @apply bg-blue;
}

.swiper-auto .swiper-slide {
  /* set auto width for swiper carousel slides with slidesPerView auto setting */
  width: auto;
}

.swiper-button-next, .swiper-button-prev {
  @apply font-bold;
}

.move-text + label {
  user-select: none;
  cursor: text;
}

.move-text:focus + label, .move-text:not(:placeholder-shown) + label {
  @apply -top-4;
  @apply text-xs;
}

.field-content {
  /* enables to control the sizing of elements like input and textarea, but */
  /* is only available for Chromium at the moment */
  field-sizing: content;
}
